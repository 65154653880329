<template>
  <v-container>
    <v-sheet height="auto">
      <v-row v-if="$route.query.status" class="mx-1">
        <v-col>
          <v-alert dense text :type="$route.query.status">
            {{ $route.query.message }}
          </v-alert>
        </v-col>
      </v-row>
      <calendar :month.sync="month" type="task-view" ref="calendar" />
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import Calendar from '@/components/admin/partials/Calendar/Calendar'
export default {
  name: 'TaskView',
  components: { Calendar },
  computed: {
    currentMonth() {
      return dayjs()
    }
  },
  data: () => ({
    month: dayjs()
  }),
  created() {
    if (this.$route.query?.date) {
      this.month = dayjs(this.$route.query.date)
    }
    this.$root.$on('logo-clicked', () => {
      if (!this.currentMonth.isSame(this.month)) {
        this.month = dayjs()
        this.$forceUpdate()
        this.$refs.calendar.getTaskData(false, true)
        if (this.$route.query?.date) {
          return this.$router.replace({
            ...this.$router.currentRoute,
            query: {}
          })
        }
      }
    })
  },
  methods: {}
}
</script>

<style src="./TaskView.scss" lang="scss" scoped></style>
