var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-sheet', {
    attrs: {
      "height": "auto"
    }
  }, [_vm.$route.query.status ? _c('v-row', {
    staticClass: "mx-1"
  }, [_c('v-col', [_c('v-alert', {
    attrs: {
      "dense": "",
      "text": "",
      "type": _vm.$route.query.status
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.query.message) + " ")])], 1)], 1) : _vm._e(), _c('calendar', {
    ref: "calendar",
    attrs: {
      "month": _vm.month,
      "type": "task-view"
    },
    on: {
      "update:month": function updateMonth($event) {
        _vm.month = $event;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }